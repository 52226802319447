<template>
  <div class="desk-about">
    <Navigation :bgColor='bgColor' :color='color' />
    <img src="~img/11.png" class="logo">
    <div class="title" v-html="desc"></div>
    <div class="img-list">
      <a :href="url_one">
        <img :src="image_one_url" alt="">
        <p>{{title_one}}</p>
      </a>
      <a :href="url_two">
        <img :src="image_two_url" alt="">
        <p>{{title_two}}</p>
      </a>
      <a :href="url_three">
        <img :src="image_three_url" alt="">
        <p>{{title_three}}</p>
      </a>
      <a :href="url_four">
        <img :src="image_four_url" alt="">
        <p>{{title_four}}</p>
      </a>
    </div>
    <horserace :color='noticeColor' :background='background' />
  </div>
</template>



<script>
// import panav from "@/components/pc/nav.vue";
import Navigation from "@/components/web/Navigation.vue";
import horserace from "@/components/pc/horserace.vue";
export default {
  components: {
    Navigation,
    horserace,
  },
  data() {
    return {
      color: "#4aaeff",
      noticeColor: "#ffffcc",
      background: "#4aaeff",
      bgColor: "#ffffcc",
      desc: "",
      image_one_url: "",
      image_two_url: "",
      image_three_url: "",
      image_four_url: "",
      qrcode_one_url: "",
      qrcode_two_url: "",
      qrcode_three_url: "",
      qrcode_four_url: "",
      url_one: "",
      url_two: "",
      url_three: "",
      url_four: "",
      timer: "",
      showOne: true,
      showTwo: true,
      showThree: true,
      showFour: true,
      title_one: "",
      title_three: "",
      title_two: "",
      title_four: "",
    };
  },
  created() {
    this.getAbout();
  },
  methods: {
    async getAbout() {
      const res = await this.axios("/api/index/about");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        console.log(data);
        this.desc = data.desc;
        this.image_one_url = data.image_one_url;
        this.image_two_url = data.image_two_url;
        this.image_three_url = data.image_three_url;
        this.image_four_url = data.image_four_url;
        this.qrcode_one_url = data.qrcode_one_url;
        this.qrcode_two_url = data.qrcode_two_url;
        this.qrcode_three_url = data.qrcode_three_url;
        this.qrcode_four_url = data.qrcode_four_url;
        this.url_one = data.url_one;
        this.url_two = data.url_two;
        this.url_three = data.url_three;
        this.url_four = data.url_four;
        this.title_one = data.title_one;
        this.title_three = data.title_three;
        this.title_two = data.title_two;
        this.title_four = data.title_four;
      }
    },
  },
};
</script>


<style lang="less" scoped>
// #4aaeff
html {
  background: #ffffcc;
}
.desk-about {
  width: 100%;
  background: #ffffcc;
  padding-top: 200px;

  .logo {
    width: 80%;
    display: block;
    margin: auto;
    // margin-top: 4rem /* 400/100 */;
  }
  .title {
    text-align: center;
    margin-top: 0.85rem /* 85/100 */;
    color: #64471a;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: PHANBO, GLEXBL; //导航栏、筛选模块、底部跑马灯字体
    font-weight: 400;
  }
  .img-list {
    margin-top: 1.5rem /* 50/100 */;
    padding-bottom: 1.5rem /* 150/100 */;
    img {
      width: 30%;
      display: block;
      margin: auto;
      margin-top: 1rem /* 100/100 */;
    }
    p {
      font-family: PHANBO, GLEXBL;
      color: #64471a;
      text-align: center;
      margin-top: 10px;
    }
  }
}
</style>
